<template>
	<div class="popup_main">
		<div class="popup_header" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
			<slot></slot>
			<img ref="arrow_up" src="@/assets/images/arrow_down.svg" :style="{ transform: `rotate(${rotateDeg})` }" class="arrow_up" alt="" />
		</div>
		<div class="popup_select">
			<section :class="['hover_select']" @mouseenter="enterSelect" @mouseleave="leaveSelect" ref="hover_select">
				<div class="hover_inner" ref="hover_inner">
					<div
						v-for="(item, index) in hoverList"
						@click="
							() => {
								item.run();
							}
						"
						:key="index"
						class="select_item"
					>
						<div>{{ item.label }}</div>
						<!-- <img class="arrow_go" src="@/assets/images/arrow_go.svg" alt=""> -->
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		hoverList: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	data() {
		return {
			interSelect: false,
			interNav: true,
			rotateDeg: 0,
		};
	},
	mounted() {},
	methods: {
		mouseEnter() {
			this.interNav = true;
			this.$refs.hover_select.style.opacity = 1;
			let height = window.getComputedStyle(this.$refs.hover_inner).height;

			this.$refs.hover_select.style.height = height;
			this.rotateDeg = "180deg";
		},
		mouseLeave() {
			this.$refs.hover_select.style.opacity = 0;
			this.interNav = false;
			if (!this.interSelect) {
				this.$refs.hover_select.style.height = "0px";

				this.rotateDeg = 0;
			}
		},
		enterSelect() {
			this.interSelect = true;
			if (!this.interNav && this.interSelect) {
				this.$refs.hover_select.style.opacity = 1;
				let height = window.getComputedStyle(this.$refs.hover_inner).height;
				this.$refs.hover_select.style.height = height;
				this.rotateDeg = "180deg";
				this.interNav = true;
			}
		},
		leaveSelect() {
			this.interSelect = false;
			this.$refs.hover_select.style.opacity = 0;
			this.$refs.hover_select.style.height = "0px";
			this.rotateDeg = 0;
		},
	},
	beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.popup_main {
	position: relative;

	.popup_header {
		cursor: pointer;
		display: flex;
		align-items: center;

		.arrow_up {
			width: 13px;
			height: 13px;
			transition: all 0.5s linear;
		}
	}

	.popup_select {
		position: absolute;
		top: 30px;

		.hover_select {
			.hover_inner {
				.select_item {
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #999999;
					cursor: pointer;
					font-size: 12px;
					height: 40px;
					padding: 0px 8px;
				}

				.select_item:hover {
					display: flex;
					align-items: center;
					color: #fff;
					background-color: #4b4a4a;
					transition: 1s all linear;
				}

				.arrow_go {
					width: 15px;
					height: 15px;
				}

				display: flex;
				flex-direction: column;

				width: 150px;
			}

			overflow: hidden;
			padding: 15px 0px;

			width: 100%;
			background-color: #222222;
			opacity: 0;
			transition: all 0.5s;
		}
	}
}
</style>
