/*
 * @Descripttion:
 * @version:
 * @Author: yijiangang
 * @Date: 2023-03-25 09:47:30
 * @LastEditors: Andy
 * @LastEditTime: 2023-03-30 10:20:58
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import(/* webpackChunkName: "about" */ "../views/Products/Index.vue"),
		// component: () => import(/* webpackChunkName: "about" */ "../views/Home/Index.vue"),
	},
	// {
	// 	path: "/Products",
	// 	name: "Products",
	// 	component: () => import(/* webpackChunkName: "about" */ "../views/Products/Index.vue"),
	// },
	{
		path: "/Institutional",
		name: "Institutional",
		component: () => import(/* webpackChunkName: "about" */ "../views/Institutional/Index.vue"),
	},
	{
		path: "/Security",
		name: "Security",
		component: () => import(/* webpackChunkName: "about" */ "../views/Security/Index.vue"),
	},
	{
		path: "/CardApp",
		name: "CardApp",
		component: () => import(/* webpackChunkName: "about" */ "../views/Products/CardApp.vue"),
	},
	{
		path: "/BackControl",
		name: "BackControl",
		component: () => import(/* webpackChunkName: "about" */ "../views/Products/BackControl.vue"),
	},
	{
		path: "/Team",
		name: "Team",
		component: () => import(/* webpackChunkName: "about" */ "../views/AboutUs/Team.vue"),
	},
	{
		path: "/Privacy",
		name: "Privacy",
		component: () => import(/* webpackChunkName: "about" */ "../views/Privacy/Privacy.vue"),
	},
	{
		path: "/Service",
		name: "Service",
		component: () => import(/* webpackChunkName: "about" */ "../views/Service/Service.vue"),
	},
];

const router = new VueRouter({
	// mode: "history",
	// base: "/",
	routes,
});
router.beforeEach((to, from, next) => {
	console.log("hashChange");
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next();
});
router.afterEach(() => {
	window.scrollTo(0, 0);
});

export default router;
