<template>
	<div>
		<footer class="footer">
			<div class="inner_foot">
				<div ref="bottom" class="bottom"></div>
				<div class="footer_main">
					<div class="conent">
						<div class="footer_email">
							<div class="item">
								<div class="email">{{ $t("foot.email") }} :</div>
								<div class="email_content">hangsen8912@gmail.com</div>
							</div>
							<!-- <div class="item">
								<div class="email">{{ $t("foot.telephone") }} :</div>
								<div class="email_content">(852) 3796 3505</div>
							</div> -->
							<div class="item">
								<div class="email">{{ $t("foot.address") }} :</div>
								<div class="email_content">{{ $t("foot.companyAddress") }}</div>
							</div>
						</div>
					</div>
					<div class="footer_link">
						<div class="link_item" @click="goRoute('Privacy')">Privacy Policy</div>
						<div class="link_item" @click="goRoute('Service')">Terms & Conditions</div>
					</div>
					<div class="footer_border"></div>
					<div class="footer_more">© 2023 Copyright Raider Tech.</div>
					<div></div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
export default {
	data() {
		return {
			navList: [{ name: "Home" }, { name: "Products" }, { name: "Security" }, { name: "Team" }],
			hoverList: [
				{ name: "CardApp", title: "信用卡 APP" },
				{ name: "BackControl", title: "管理后台" },
			],
		};
	},
	mounted() {
		window.addEventListener("scroll", this.setAttribute);
	},
	watch: {
		$route: {
			handler() {
				this.setAttribute();
			},
		},
	},
	methods: {
		goRoute(name) {
			this.$router.push({ name: name });
		},
		setAttribute() {
			// let bottom = this.$refs.bottom;
			// let viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
			// if (bottom.offsetTop - document.documentElement.scrollTop <= viewPortHeight) {
			// 	document.documentElement.style.setProperty("--bg_color", "#000");
			// 	document.documentElement.style.setProperty("--font_color", "#fff");
			// 	document.documentElement.style.setProperty("--middle_bg", "#000");
			// } else {
			// 	document.documentElement.style.setProperty("--bg_color", "#fff");
			// 	document.documentElement.style.setProperty("--font_color", "#000");
			// 	document.documentElement.style.setProperty("--middle_bg", "#f4f4f4");
			// }
		},
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.setAttribute);
	},
};
</script>
<style lang="less" scoped>
.footer {
	.inner_foot {
		.footer_main {
			.conent {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				padding-top: 30px;
			}

			.footer_more {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #999999;
				font-size: 8px;
				padding: 10px 0px;
			}

			.footer_border {
				width: 100%;
				height: 1px;
				background: hsla(0, 0%, 100%, 0.6);
				transform: scaleY(0.5);
			}

			.footer_link {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 10px;
				color: #999999;
				padding-bottom: 10px;
				margin-top: 16px;
				letter-spacing: 0.65px;
				.link_content:hover {
					color: #fff;
				}
				.link_item {
					cursor: pointer;
				}

				.link_item:hover {
					color: #fff;
				}

				display: flex;
				color: #999999;
			}

			.footer_email {
				font-size: 10px;

				.item {
					display: flex;
					align-items: flex-start;
					margin-top: 6px;
					&:last-child {
						margin-top: 16px;
					}

					.email {
						text-align: right;
						letter-spacing: 1px;
					}
				}
				.email {
					color: #999999;
					margin-right: 4px;
					white-space: nowrap;
				}

				.email_content {
					color: #999999;
					letter-spacing: 0.7px;
				}

				// .email_content:hover {
				// 	color: #fff;
				// }
			}

			.footer_header {
				.nav_right {
					color: #999999;
					display: flex;
					align-items: center;

					font-size: 10px;

					.nav_item {
						margin-left: 20px;
						cursor: pointer;
					}

					.nav_item:hover {
						color: #fff;
					}
				}

				.logo {
					color: #fff;
					font-size: 12px;
				}

				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
			}
		}

		.partner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			color: var(--font_color);
			font-size: 20px;
			height: 110px;
		}

		.foot_title {
			text-align: center;
			font-size: 16px;
			color: var(--font_color);
		}

		padding-top: 0px;
		margin: 60px auto 0px;
		width: 565px;
	}
	background-color: #000000;
	width: 100%;
}
</style>
