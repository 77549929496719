<!--
 * @Descripttion: 
 * @version: 
 * @Author: yijiangang
 * @Date: 2023-03-27 16:54:34
 * @LastEditors: Andy
 * @LastEditTime: 2023-03-30 14:22:32
-->
<template>
	<div>
		<header class="header">
			<nav class="header_left" @click="goRoute('Home')"><img class="logo" src="@/assets/logo.png" alt="" /></nav>
			<nav class="header_middle">
				<!-- <Popup :hoverList="hoverList">
					<div :class="['nav_middle_item', itemSelected('Products')]" @click="goRoute('Products')">Products</div>
				</Popup> -->
				<div :class="['nav_middle_item', itemSelected(item.name)]" @click="goRoute(item.name)" v-for="(item, index) in navList" :key="index">{{ item.title }}</div>
				<!-- <Popup :hoverList="langs">
					<div :class="['nav_middle_item']">{{ $t("nav.lan") }}</div>
				</Popup> -->
			</nav>
			<!-- <nav class="header_right">Register</nav> -->
		</header>
	</div>
</template>

<script>
export default {
	data() {
		return {
			navList: [
				{ name: "Home", title: "Products" },
				{ name: "Security", title: "Security" },
				{ name: "Team", title: "Team" },
			],
			hoverList: [
				{
					run: () => {
						this.$router.push({ name: "CardApp" });
					},
					label: "APP",
				},
				{
					run: () => {
						this.$router.push({ name: "BackControl" });
					},
					label: "Backstage Service",
				},
			],
			index: -1,
			name: "",
			langs: [
				{
					label: "English",
					run: () => {
						this.changeLang("en");
					},
				},
				{
					label: "中文",
					run: () => {
						this.changeLang("zh");
					},
				},
			],
		};
	},
	computed: {
		itemSelected() {
			return (name) => {
				return name == this.name ? "active" : "unactive";
			};
		},
	},
	watch: {
		$route: {
			handler(route) {
				this.name = route.name;
			},
		},
	},
	methods: {
		changeLang(lan) {
			this.$i18n.locale = lan;
			localStorage.setItem("lang", lan);
			// this.$router.go(0);
		},
		goRoute(name) {
			this.$router.push({ name: name });
		},
	},
	beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.header {
	width: 100%;
	height: 45px;
	background: #222;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	left: 0;
	top: 0;
	color: #fff;
	z-index: 9999;

	.header_left {
		position: absolute;
		left: 45px;
		cursor: pointer;
		.logo {
			width: 30px;
		}
	}

	.header_middle {
		position: absolute;
		right: 25%;
		display: flex;
		align-items: center;
		gap: 30px;

		.nav_middle_item {
			padding: 0px 10px;
			height: 100%;
			cursor: pointer;
			color: hsla(0, 0%, 100%, 0.6);
		}

		.active {
			border-bottom: 2px solid transparent;
			border-image: linear-gradient(90deg, #8ff8ff, #87ffa1);
			color: #fff;
		}

		.nav_middle_item:hover {
			color: #fff;
		}
	}

	.header_right {
		position: absolute;
		right: 45px;
	}
}
</style>
