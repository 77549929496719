<template>
  <div class="swiper_box">轮播组件2
11111111111111111111111111111111111111111111111111111111111
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  },
  beforeDestroy() {

  },
}
</script>
<style lang='less' scoped>
.swiper_box{
    color: #fff;
    width:100%;
    height:100%;
}

</style>