<template>
    <div class="swiper">
        <div class="indicator">
            <div v-for="(item, index) in swiperList.length" @click="activeSelect(index)" :key="index"
                :class="['indicator_item', itemActive(index)]"></div>
        </div>
        <div class="swiper_main">
            <div class="swiper_inner" @mousemove.prevent="handleDragMove" @mousedown.prevent="handleDragStart"
                @mouseout="play()" @mouseover="stop()" @mouseup.prevent="handleDragEnd" ref="swiper_inner"
                :style="{ transform: `translate(-${itemWidth * currentIndex}px)` }">
                <div v-for="(item, index) in swiperList" :is="item.component" :key="index" class="swiper_item">{{ item }}
                </div>
            </div>

        </div>

    </div>
</template>

<script>

export default {
    data() {
        return {
            // list: [{ title: "Js" }, { title: "Vue" }, { title: "React" }, { title: "Solid" }, { title: "Js" }],
            currentIndex: 0,
            itemWidth: 0,
            timer: null,
        }
    },
    props: ["swiperList"],
    computed: {
        itemActive() {
            {
                return index => {
                    return index == this.currentIndex ? "indicator_item_active" : "indicator_item"
                }
            }
        }
    },
    mounted() {
        // 进入页面获取宽度
        this.changeWidth()

        window.addEventListener("resize", this.changeWidth)
        this.play()
    },
    methods: {
        handleDragStart() {

        },
        changeWidth() {

            this.itemWidth = this.$refs.swiper_inner.clientWidth / 2
            console.log(this.itemWidth)

        },
        stop() {
            console.log(clearInterval(this.timer))
            window.clearInterval(this.timer)
        },
        handleDragEnd() { },
        handleDragMove() { },
        autoPlay() {
            this.currentIndex++;
            if (this.currentIndex === this.swiperList.length) { //当遍历到最后一张图片置零
                this.currentIndex = 0
            }
        },
        play() {
            if (this.timer) clearInterval(this.timer)
            this.timer = setInterval(() => {
                this.autoPlay()
            }, 3000)
        },
        activeSelect(index) {
            this.currentIndex = index
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
        window.removeEventListener('resize', this.changeWidth);
    },
}
</script>
<style lang='less' scoped>
.swiper {
    .indicator {
        display: flex;
        gap: 15px;

        .indicator_item {
            width: 5px;
            height: 5px;
            background-color: #fff;
            cursor: pointer;
        }

        .indicator_item_active {
            width: 40px;
            cursor: pointer;
            background: linear-gradient(#8ff8ff, #87ffa1) !important;
        }
    }

    .swiper_main {
        .swiper_inner {
            width: fit-content;
            display: flex;
            border: 1px solid blue;
            transition: all 1s;

            .swiper_item {
                display: inline-block;
                height: 250px;
                width: 400px;
                flex-shrink: 0;
                color: #fff;
                padding: 0;
                margin: 0;
            }
        }

        // overflow: hidden;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    width: 400px;
    border: 1px solid red;
    position: absolute;
    top: 75px;
}
</style>