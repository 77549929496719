<!--
 * @Descripttion: 
 * @version: 
 * @Author: yijiangang
 * @Date: 2023-03-25 09:47:30
 * @LastEditors: Andy
 * @LastEditTime: 2023-03-30 09:16:18
-->
<template>
  <div id="app" class="pageMain">
    <Header></Header>
    <router-view v-slot="{ Component }">
      <transition name="scale-slide" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <Footer></Footer>
  </div>
</template>
<script>

export default {
  mounted() {

  },
  methods: {

  },

}
</script>
<style lang="less">
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}

.pageMain {}
</style>
