/*
 * @Descripttion:
 * @version:
 * @Author: yijiangang
 * @Date: 2023-03-25 09:47:30
 * @LastEditors: Andy
 * @LastEditTime: 2023-03-28 17:26:47
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/global.css";
import "./assets/css/top.less";
import "./assets/font/font.css";
import "animate.css";
Vue.config.productionTip = false;
let map = require.context("./components", true, /\.vue$/);
console.log(map);
map.keys().map((item) => {
	let name = item.split("/").pop().split(".")[0];
	Vue.component(name, map(item).default);
});
// 国际化
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
// 默认英文
if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", "en");
}
const i18n = new VueI18n({
	locale: localStorage.getItem("lang"),
	messages: {
		zh: require("./assets/lang/zh.json"),
		en: require("./assets/lang/en.json"),
	},
});
new Vue({
	router,
	render: (h) => h(App),
	i18n,
}).$mount("#app");
